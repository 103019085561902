import React, { Component } from "react";
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { createScene } from './createScene';
import { createCSSScene } from './createCSSScene';
import { createLabels } from './createLabels';
import { createBG } from './createBG';
import { createLights } from './createLights';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import request from 'superagent';
import SvgContainer from './svgContainer';
import Stats from 'three/examples/jsm/libs/stats.module'

class App extends Component {
	  
	constructor(props){
		super(props)
		this.state = { 
			isMounted: true,
			progressPct: 0,
			labelActive: false,
			labelObjects: [],
			mouse: { x: 0, y: 0 },
			cameraInit: { 
				position: { x: 0, y: -30, z: 250 },
				rotation: { x: 0, y: 0, z: 0 }
			},
			cameraMove: {
				position: { x: 0, y: -30, z: 250 }, // -20 | 230
				lookAt: { x: 0, y: -20, z: 0 }
			},
			labels: [
				{ 
					title: 'Project Dashboard', 
					initPosition: { x: 90, y: 35, z: 8 },
					buttonText: 'Learn More',
					imageURL: 'https://s3.envato.com/files/235783482/syda_0242392.jpg',
					text: 'This is some text about this callout. Would you like to learn more? <p>There really is a lot of interesting things to learn. Give it a try! What do you think?'
				},
				{ 
					title: 'Project Editor', 
					initPosition: { x: -106, y: 10, z: 8 },
					buttonText: 'Learn More',
					imageURL: 'https://s3.envato.com/files/235783482/syda_0242392.jpg',
					text: 'This is some text about this callout. Would you like to learn more? <p>There really is a lot of interesting things to learn.'
				},
				{ 
					title: 'Workspace Details', 
					initPosition: { x: 100, y: -30, z: 8 },
					buttonText: 'Learn More',
					imageURL: 'https://s3.envato.com/files/235783482/syda_0242392.jpg',
					text: 'This is some text about this callout. Would you like to learn more? <p>There really is a lot of interesting things to learn.'
				}
			],
			media: null,
			mediaTexture: null,
			mediaImageContext: null
	 	}
	 	this.addDevice = this.addDevice.bind(this);
	 	this.loadObject = this.loadObject.bind(this);
	 	
	 	this.windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );
	 	this.mouse = new THREE.Vector2();
	 	
		this.currentTime = 0;
		this.intervalRewind = null;
        
        this.stats = new Stats();
		this.stats.showPanel( 0,2 ); // 0: fps, 1: ms, 2: mb, 3+: custom
// 		document.body.appendChild( this.stats.dom );
	}
	
    componentDidMount() {
	    
	    // CREATE WEBGL SCENE
        [ this.scene, this.camera, this.renderer, this.controls, this.effectComposer ] = createScene( this.mount );

	    // CREATE CSS3D SCENE
        [ this.css_scene, this.css_renderer ] = createCSSScene( this.mount );
	    	    
	    // CREATE BG
//         this.bgPlane = createBG( this.scene,this.camera );
        
        // ADD DEVICE
        this.addDevice();
        
    }

    componentWillUnmount() {
	    
	    // RESET THREE
        window.removeEventListener('resize', this.handleWindowResize);
        window.cancelAnimationFrame(this.requestID);
//         this.controls.dispose();
        
    }
    
    handleClick = (e) => {
	    
	    if (this.state.labelActive === false || e.target.className === "labelTitle")
	    return;
	    
	    this.handleLabelClick( this.state.labelActive,'close' );
    }

    handleWindowResize = () => {
	    
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

	    // UPDATE SIZE OF RENDERER
        this.renderer.setSize( width, height );
        this.css_renderer.setSize( width, height );
        
        // UPDATE CAMERA
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        
        // MOUSE MOVEMENT
        this.windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );
        
    }
    
    onMouseMove = ( event ) => {

		this.mouse.x = ( event.clientX - this.windowHalf.x );
		this.mouse.y = ( event.clientY - this.windowHalf.y );
		
		this.setState({
		    mouse: {
		          ...this.state.mouse,
		          x: this.mouse.x / this.windowHalf.x,
		          y: this.mouse.y / this.windowHalf.y * -1
		    }
		})
		
	}

	/* DEVICE */

	loadObject = () => {
		
		  
	  const manager = new THREE.LoadingManager();
	  
		manager.onStart = (url, itemsLoaded, itemsTotal) => {};
		
		manager.onLoad = () => {};
		
		manager.onProgress = (url, itemsLoaded, itemsTotal) => { 
			const progressPct = itemsLoaded / itemsTotal * 100;
			if (progressPct === this.state.progressPct)
			return;
			
			this.setState({ progressPct: progressPct })
		};
		
		manager.onError = function ( url ) {};
		
	  return new Promise((resolve, reject) => {
		  
		const loader = new GLTFLoader( manager ).setPath( process.env.PUBLIC_URL + "/models/opul/" );
		
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath( process.env.PUBLIC_URL + "/threejs/draco/" );
		loader.setDRACOLoader( dracoLoader );

	    loader.load( "opul.glb", function ( object ) {
			resolve(object.scene)
		});
		
	  });
	  
	}
	
	
	fetchVideoAndUpdatePercent = (url) => {
	  request
		  .get(url)
		  .responseType('blob')
		  .on('progress', (e) => {
	      		//console.log(e.percent)
		  })
		  .end((err, res) => {
				if (!err) {
					const blob = res.body;
// 					this.state.media.src = URL.createObjectURL(blob);
					
					
					let media = this.state.media;
					media.src = URL.createObjectURL(blob);
					this.setState({media: media})
				}
	
				if (err) {
					console.log(err);
				}
		  });
	}
	
	createMedia = () => {
	
		  return new Promise((resolve, reject) => {
			  
			let isImage = true;
	
		    let media = document.createElement(isImage ? 'img' : 'video');
		    media.crossOrigin = "anonymous";
		    
		    this.setState({media: media})
		    
		    this.fetchVideoAndUpdatePercent(process.env.PUBLIC_URL + "/threejs/screen/screen.jpg");
		   
		    this.state.media.addEventListener(isImage ? 'load' : 'loadeddata', () => {
	
				const mediaImage = document.createElement( 'canvas' );
				
				const canvasWidth = 2048;
				const canvasHeight = 1024;
				mediaImage.width = canvasWidth;
				mediaImage.height = canvasHeight;

				const mediaImageContext = mediaImage.getContext( '2d' );
				mediaImageContext.fillStyle = '#000000';
				mediaImageContext.fillRect( 0, 0, canvasWidth, canvasHeight );
				mediaImageContext.drawImage(this.state.media, 0, 0, canvasWidth, canvasHeight);
		
				// MEDIA SPEED
				if (!isImage){
					let media = this.state.media;
					media.playbackRate = media.duration / 1; // match speed of camera
					this.setState({media: media})
				}
				
				this.setState({mediaImageContext: mediaImageContext})

				const mediaTexture = new THREE.Texture( mediaImage );
				mediaTexture.wrapT = THREE.RepeatWrapping;
				mediaTexture.encoding = THREE.sRGBEncoding;
				mediaTexture.minFilter = THREE.LinearFilter;
				mediaTexture.magFilter = THREE.LinearFilter;
				mediaTexture.repeat.y = -1;
				
				this.setState({mediaTexture: mediaTexture})

				// NEW REFLECTION

				var svg = document.getElementById("svg_reflection");
				var svgData = (new XMLSerializer()).serializeToString(svg);

				var canvas = document.createElement("canvas");
				canvas.width = 8096;
				canvas.height = 4048;
				var ctx = canvas.getContext("2d");
				var offsetX = 2958, offsetY = 0;
	
				var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
				if (isSafari) ctx.translate(offsetX, offsetY);
				else ctx.translate(offsetX + 900, offsetY)

				var img = document.createElement("img");
				img.setAttribute("src", "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(svgData))) );

				img.onload = function() {
					
					ctx.drawImage(img, 0, 0);

					var envMapScreen = new THREE.Texture(canvas);
					envMapScreen.needsUpdate = true;
					envMapScreen.minFilter = THREE.LinearFilter;
					envMapScreen.magFilter = THREE.LinearFilter;
					envMapScreen.mapping = THREE.EquirectangularReflectionMapping;

					var mediaMaterial = new THREE.MeshPhongMaterial({
						emissiveMap: mediaTexture,
						emissive:0xffffff,
						emissiveIntensity: 1,
						color:0x000000,
						map: mediaTexture,
						envMap: envMapScreen,
						specular:0x000000,
						shininess:10,
						reflectivity:.12,
						side: THREE.DoubleSide,
						combine:THREE.AddOperation,
						transparent:true,
						dithering:true
					});
						
					mediaMaterial.needsUpdate = true;

					return resolve( mediaMaterial );

				};
	    	});
	  	});
	
	}

    addDevice = async () => {
        
        // LOAD OBJECT - wait for it to load before adding to scene
        const object = await this.loadObject();
        const mediaMaterial = await this.createMedia('device');
		
        object.traverse((child) => {
	        if (child.name.includes('screen') === true){
				child.receiveShadow = true;
			}
			child.castShadow = false;
			
			if (child.name === "screen") {
		      child.material = mediaMaterial;
		    }
		});
		
        this.scene.add( object );  
        
        // RUN REMAINING SCRIPTS
        this.postDeviceLoad();
        
    };
    
    
    postDeviceLoad = () => {
	    
        // CREATE LIGHTS
        createLights( this.scene );
        
        // CREATE LABELS
        this.setState({labelObjects: createLabels( 
        	this.state.labels,
        	this.css_scene,
        	this.scene,
        	this.state.labelActive,
        	this.handleLabelClick,
        	this.handleLabelHover )
        });
        
        // ANIMATION
        this.animate();
        
        // HANDLE WINDOW RESIZE
        window.addEventListener('resize', this.handleWindowResize);
        
        // HANDLE MOUSE MOVEMENT
        window.addEventListener( 'mousemove', this.onMouseMove, false );
        
    }
    
    
    playVideo = ( direction ) => {
	    
	    this.state.media.pause();
	    
	    // PLAY
	    
	    if ( direction === 'forward' ){
		    clearInterval(this.intervalRewind);
		    this.currentTime = 0;
		    this.state.media.play();
		    return;
	    }
	    
	    // REWIND
		
		let fps = 60;
		this.currentTime = this.state.media.duration;
	    this.intervalRewind = setInterval( () => {
	        if(this.currentTime <= 0){
	           this.currentTime = 0;
	           clearInterval(this.intervalRewind);
	        }
	        else {
				this.currentTime -= ((1/fps) * this.state.media.duration).toFixed(2);
	        }
	    }, 1000 / fps);
	    
    }
    
	
	/* LABEL CLICK */
	
	handleLabelClick = ( label,state ) => {
		
		
		 clearInterval(this.intervalRewind);
	
		// DON'T DO ANYTHING IF TRYING TO OPEN WHEN ALREADY OPEN
		
		if ( this.state.labelActive && state === 'open' )
		return;
			
		// SET ACTIVE STATE
		
		this.setState({ labelActive: ( state === 'open' ? label : false ) });
		
		// PLAY VIDEO
		
		let playDirection = state === 'open' ? 'forward' : 'backward';
		if (!!this.state.media.play)
		this.playVideo( playDirection );
		
		// ANIMATE CAMERA
		
		let targetPosition = [];
		targetPosition.x = label.initPosition.x + (label.initPosition.x / 60);
		targetPosition.y = label.initPosition.y - 23 - (label.initPosition.y / 5);
		targetPosition.z = label.initPosition.z + 110;
		
		let targetLookPosition = [];
		targetLookPosition.x = label.initPosition.x - (label.initPosition.x / 5);
		targetLookPosition.y = label.initPosition.y;
		targetLookPosition.z = 0;
		
		if ( state === 'close' ){
			targetPosition = this.state.cameraInit.position;
			targetLookPosition = this.state.cameraInit.position;
		}
		
		// MOVE CAMERA TO TARGET (LABEL OR DEFAULT)
		
		new TWEEN.Tween( this.state.cameraMove.position )
			.to(  targetPosition, 1000 )
			.easing( TWEEN.Easing.Quartic.InOut )
			.onStart(() => {
				
				// MOVE LOOKAT TO LABEL
			    new TWEEN.Tween( this.state.cameraMove.lookAt )
					.to(  targetLookPosition, 1000 )
					.easing( TWEEN.Easing.Quartic.InOut )
					.start();
					
			})
			.start()
		
		// ANIMATE LABEL OBJ
		
		if (state === 'open'){
			label.labelDiv.classList.add('open');
			label.labelButton.classList.add('open');
		}
		else {
			label.labelDiv.classList.remove('open');
			label.labelButton.classList.remove('open');
		}
			
		// CLOSE OTHER LABELS
			
		Object.keys(this.state.labelObjects).map((key) => {
				
			if (this.state.labelObjects[key] === label)
			return false;
			
			const labelDiv = this.state.labelObjects[key].labelDiv;
			
			if ( state === 'open')
			labelDiv.classList.add('close');
			else
			labelDiv.classList.remove('close');
			
			return true;
		
		});
			
	}
	
	
	handleLabelHover = ( label,action ) => {
		
		if (this.state.labelActive) 
		return;
		
		let target = { x : 0, y : 0, z: (action === 'enter' ? -1 : 1) };
		let position = { x : 0, y: 0, z: 0 };
		
		new TWEEN.Tween( position )
			.to( { z: target.z }, 300 )
			.easing( TWEEN.Easing.Quartic.InOut )
			.onUpdate(function(){
				//label.labelMesh.position.z = label.initPosition.z + position.z;
				label.labelCSS.position.z = label.initPosition.z + position.z;
			})
			.start();
	}

	/* ANIMATE */

    animate = () => {
			
 		TWEEN.update();
 		
 		this.stats.begin();
 		this.stats.end();
	    
		// LOCK BG TO CAMERA
		if (this.bgPlane){
			this.bgPlane.position.copy( this.camera.position );
			this.bgPlane.rotation.copy( this.camera.rotation );
			this.bgPlane.position.set( 0,0,-500 );
			this.bgPlane.updateMatrix();
		}
		
		
		// FOR VIDEO
		const isVideo = !!this.state.media.play;
		if (isVideo && this.state.media.readyState === this.state.media.HAVE_ENOUGH_DATA) {
			
			if (this.currentTime != 0){
				let media = this.state.media;
				media.currentTime = this.currentTime;
				this.setState({media: media});
				console.log(this.currentTime)
			}
				
		  	const canvasWidth = 6016/3;
			const canvasHeight = 3384/3;
			this.state.mediaImageContext.drawImage(this.state.media, 0, 0, canvasWidth, canvasHeight);
		}
		
		// UPDATE TEXTURE
		let mediaTexture = this.state.mediaTexture;
		mediaTexture.needsUpdate = true;
		this.setState({mediaTexture: mediaTexture})
		
		// CAMERA MOVEMENT
		const slideOffset = Math.abs(this.state.cameraMove.position.x / 100);
		const camMult = 6 - slideOffset * 4;
		this.camera.position.x = this.state.cameraMove.position.x - this.state.mouse.x * camMult;
		this.camera.position.y = this.state.cameraMove.position.y - this.state.mouse.y * camMult;
		this.camera.position.z = this.state.cameraMove.position.z;
		
		this.camera.lookAt(this.state.cameraMove.lookAt.x, this.state.cameraMove.position.y, 0);
		
		// Render out scene
        this.renderer.render( this.scene, this.camera );
// 		this.effectComposer.render( this.scene, this.camera );
        this.css_renderer.render( this.css_scene, this.camera );

        // Keep updating every frame
        setTimeout( () => {

       		this.requestID = window.requestAnimationFrame(this.animate);
	
	    }, 1000 / 60 );
    };


	/* RENDER */

    render() {
        return (
	        <div className="viewportContainer">
	        	<div className="progress" style={{opacity: (this.state.progressPct === 100 ? 0 : 1 )}}>
	        		<div style={{transform: 'scale('+this.state.progressPct/100+',1)'}} />
	        	</div>
                <div className={"viewport " + ( this.state.labelActive ? 'darken' : '' ) } style={{opacity: (this.state.progressPct === 100 ? 1 : 1 )}} ref={ref => (this.mount = ref)} onClick={ this.handleClick }>
                </div>
        	</div>
        )
    }
}


class Viewport extends Component {
	  
	constructor(props){
		super(props)
		this.state = { 
			isMounted: true,
			sideSelected: 'left'
	 	}
	}

    render() {
        return (
	        <>
	        	<SvgContainer />
	        	
	        	<button className="mountButton" onClick={() => this.setState({ isMounted: !this.state.isMounted })}>
                    {this.state.isMounted ? "Unmount" : "Mount"}
                </button>
                
                {this.state.isMounted && 
	                <App />
	            }
	            
	            
	            
	        	<div className="label_hidden">
		        	<div id="label_0">
						<div className="labelTitle">
							Treatment Plan Creation
						</div>
						<div className={"labelGraphic " + this.state.sideSelected}>
							<div className="labelGraphicInner">
								<div className="labelGraphic-block left" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + "/threejs/highlights/highlight_02.png" + `")`}}>
								</div>
								<div className="labelGraphic-block right" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + "/threejs/highlights/highlight_01.png" + `")`}}>
								</div>
							</div>
						</div>
					    <div className={"labelText " + this.state.sideSelected }>
						    <div className="labelText-block left" onClick={() => this.setState({ sideSelected: "left" })}>
							    <div className="demo-text a01"></div>
							    <div className="demo-text a02"></div>
							    <div className="demo-text a03"></div>
						    </div>
						    <div className="labelText-block right" onClick={() => this.setState({ sideSelected: "right" })}>
							    <div className="demo-text a01"></div>
							    <div className="demo-text b02"></div>
							    <div className="demo-text b03"></div>
						    </div>
						</div>
					</div>
		        	<div id="label_1">
						<div className="labelTitle">
							Patient Lookup
						</div>
						<div className={"labelGraphic " + this.state.sideSelected}>
							<div className="labelGraphicInner">
								<div className="labelGraphic-block left" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + "/threejs/highlights/highlight_02.png" + `")`}}>
								</div>
								<div className="labelGraphic-block right" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + "/threejs/highlights/highlight_01.png" + `")`}}>
								</div>
							</div>
						</div>
					    <div className={"labelText " + this.state.sideSelected }>
						    <div className="labelText-block left" onClick={() => this.setState({ sideSelected: "left" })}>
							    <div className="demo-text a01"></div>
							    <div className="demo-text a02"></div>
							    <div className="demo-text a03"></div>
						    </div>
						    <div className="labelText-block right" onClick={() => this.setState({ sideSelected: "right" })}>
							    <div className="demo-text a01"></div>
							    <div className="demo-text b02"></div>
							    <div className="demo-text b03"></div>
						    </div>
						</div>
					</div>
		        	<div id="label_2">
						<div className="labelTitle">
							Use Anywhere
						</div>
						<div className={"labelGraphic " + this.state.sideSelected}>
							<div className="labelGraphicInner">
								<div className="labelGraphic-block left" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + "/threejs/highlights/highlight_02.png" + `")`}}>
								</div>
								<div className="labelGraphic-block right" style={{backgroundImage: `url("` + process.env.PUBLIC_URL + "/threejs/highlights/highlight_01.png" + `")`}}>
								</div>
							</div>
						</div>
					    <div className={"labelText " + this.state.sideSelected }>
						    <div className="labelText-block left" onClick={() => this.setState({ sideSelected: "left" })}>
							    <div className="demo-text a01"></div>
							    <div className="demo-text a02"></div>
							    <div className="demo-text a03"></div>
						    </div>
						    <div className="labelText-block right" onClick={() => this.setState({ sideSelected: "right" })}>
							    <div className="demo-text a01"></div>
							    <div className="demo-text b02"></div>
							    <div className="demo-text b03"></div>
						    </div>
						</div>
					</div>
				</div>
				
				
        	</>
        )
    }
}
export default Viewport;