import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

export function createScene( mount ){
	
    const width = mount.clientWidth;
    const height = mount.clientHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        50, // fov = field of view
        width / height, // aspect ratio
        0.1, // near plane
        60000 // far plane
    );
    
    camera.lookAt(0, -30, 0);
    
//    const controls = new OrbitControls( camera, mount );
 	const controls = null;
	
	var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	
	const options = {
		antialias: ( isSafari ? true : true ),
		pixelRatio: ( isSafari ? window.devicePixelRatio : window.devicePixelRatio )
	}
	
    
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: options.antialias });
    renderer.setSize( width, height );
    renderer.gammaFactor = 2.2;
	renderer.outputEncoding = THREE.sRGBEncoding;
	renderer.setClearColor(0xffffff, 0);
	renderer.setPixelRatio( options.pixelRatio );
// 	renderer.shadowMap.enabled = true;
	renderer.physicallyCorrectLights = 1;
	
	
		

	const parameters = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat, stencilBuffer: false };
	
	const renderTarget = new THREE.WebGLRenderTarget( width * options.pixelRatio, height * options.pixelRatio, parameters );
	
	const effectComposer = new EffectComposer( renderer , renderTarget );
	effectComposer.setSize( width, height );

	const renderPass = new RenderPass( scene, camera );
	renderPass.renderToScreen = true;
	effectComposer.addPass( renderPass );

    
    // ADD SCENE TO MOUNT
    mount.appendChild( renderer.domElement );
    
    return [ scene, camera, renderer, controls, effectComposer ];
    
};
	
export default createScene;