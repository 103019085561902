import React from 'react';


const svgContainer = () => (



  <div id="svgContainer" style={{width: '200px', height: '200px', display: 'none'}}>

    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
    	viewBox="0 0 10612 5271.3"
      style={{enableBackground: 'new 0 0 10612 5271.3'}}
      xmlSpace="preserve"
      id="svg_reflection"
    >
      <style type="text/css" dangerouslySetInnerHTML={{__html: `
      	.st0{display:none;}
      	.st1{fill:url(#Light_1_);}
      	.st2{fill:url(#HideSide_1_);}
      	.st3{fill:url(#HideBottom_1_);}
      	.st4{fill:url(#BigBlob2_4_);}
      	.st5{fill:url(#BigBlob2_5_);}
      	.st6{fill:url(#BigBlob2_6_);}
      	.st7{fill:url(#BigBlob2_7_);}
      `}} />

      <g id="Layer_1" className="st0">

      		<image
            style={{display: 'inline', overflow: 'visible'}}
            width="8096"
            height="4048"
            transform="matrix(1.3108 0 0 1.3108 0 -17.3399)"
          />

      </g>

      <g id="Layer_2">
      	<g id="Layer_4">
      		<rect x="0.3" width="10611.5" height="5388.4" />
      	</g>

    		<radialGradient id="Light_1_" cx="2839.3738" cy="2706.4966" r="1205.9288" gradientTransform="matrix(0.9489 0.3157 -0.5024 1.51 1593.2948 -3011.0288)" gradientUnits="userSpaceOnUse">
      		<stop  offset="0" style={{stopColor: '#FFFFFF'}} />
      		<stop  offset="0.2492" style={{stopColor: '#B3B3B3'}} />
      		<stop  offset="0.5198" style={{stopColor: '#676767'}} />
      		<stop  offset="0.7442" style={{stopColor: '#2F2F2F'}} />
      		<stop  offset="0.9103" style={{stopColor: '#0D0D0D'}} />
      		<stop  offset="1" style={{stopColor: '#000000'}} />
      	</radialGradient>

      	<path id="Light" className="st1" d="M4071.9,2353c-338.7,1018.1-1125.6,1673-1757.5,1462.7s-869.6-1206-530.9-2224.1
      		S2909.1-81.4,3541,128.8S4410.6,1334.9,4071.9,2353z" />
      	<polygon id="Cover" points="3568.5,106.1 1922.5,4299.9 3635.8,4486.5 4823.8,598.4" />
      	<linearGradient id="HideSide_1_" gradientUnits="userSpaceOnUse" x1="1088.0135" y1="2094.8938" x2="2897.1643" y2="2094.8938">
      		<stop  offset="0" style={{stopColor: '#000000'}} />
      		<stop  offset="1" style={{stopColor: '#000000', stopOpacity: '0'}} />
      	</linearGradient>
      	<rect id="HideSide" x="1088" y="-30" className="st2" width="1813" height="4249.8" />

      		<linearGradient id="HideBottom_1_" gradientUnits="userSpaceOnUse" x1="54.2103" y1="2934.0339" x2="2410.7449" y2="2934.0339" gradientTransform="matrix(6.123234e-17 -1 1 6.123234e-17 -100.3609 4089.4268)">
      		<stop  offset="0" style={{stopColor: '#000000'}} />
      		<stop  offset="1" style={{stopColor: '#000000', stopOpacity: '0'}} />
      	</linearGradient>
      	<polygon id="HideBottom" className="st3" points="4958.6,1673.6 4958.6,4035.2 708.8,4035.2 708.8,1673.6" />

      		<radialGradient id="BigBlob2_4_" cx="3820.2593" cy="2781.6089" r="1020.0933" gradientTransform="matrix(1 0 0 1.5914 100.8373 -1572.3101)" gradientUnits="userSpaceOnUse">
      		<stop  offset="0" style={{stopColor: '#666666'}} />
      		<stop  offset="0.4642" style={{stopColor: '#2F2F2F'}} />
      		<stop  offset="0.812" style={{stopColor: '#0D0D0D'}} />
      		<stop  offset="1" style={{stopColor: '#000000'}} />
      	</radialGradient>
      	<ellipse id="BigBlob2" className="st4" cx="3921.1" cy="2854.4" rx="1020" ry="1643.4" />

      		<radialGradient id="BigBlob2_5_" cx="-100.5827" cy="1540.6117" r="1020.0933" gradientTransform="matrix(1 0 0 1.5914 100.8373 -838.351)" gradientUnits="userSpaceOnUse">
      		<stop  offset="0" style={{stopColor: '#666666'}} />
      		<stop  offset="0.4642" style={{stopColor: '#2F2F2F'}} />
      		<stop  offset="0.812" style={{stopColor: '#0D0D0D'}} />
      		<stop  offset="1" style={{stopColor: '#000000'}} />
      	</radialGradient>
      	<ellipse id="BigBlob2_2_" className="st5" cx="0.3" cy="1613.4" rx="1020" ry="1643.4" />

      		<radialGradient id="BigBlob2_6_" cx="10511.1631" cy="1540.6117" r="1020.0933" gradientTransform="matrix(1 0 0 1.5914 100.8373 -838.351)" gradientUnits="userSpaceOnUse">
      		<stop  offset="0" style={{stopColor: '#666666'}} />
      		<stop  offset="0.4642" style={{stopColor: '#2F2F2F'}} />
      		<stop  offset="0.812" style={{stopColor: '#0D0D0D'}} />
      		<stop  offset="1" style={{stopColor: '#000000'}} />
      	</radialGradient>
      	<ellipse id="BigBlob2_3_" className="st6" cx="10612" cy="1613.4" rx="1020" ry="1643.4" />

      		<radialGradient id="BigBlob2_7_" cx="9058.6758" cy="2494.9524" r="1930.6245" gradientTransform="matrix(1.1601 0 0 1.5914 -2136.446 -1402.7737)" gradientUnits="userSpaceOnUse">
      		<stop  offset="0" style={{stopColor: '#FFFFFF'}} />
      		<stop  offset="1" style={{stopColor: '#FFFFFF', stopOpacity: '0'}} />
      	</radialGradient>
      	<ellipse id="BigBlob2_1_" className="st7" cx="8372.4" cy="2567.8" rx="2239.6" ry="3110.3" />
      </g>
    </svg>

  </div>
);

export default svgContainer;
