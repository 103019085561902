import * as THREE from "three";
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

export function createLabels ( labels,css_scene,scene,labelActive,handleLabelClick,handleLabelHover ){
		
	const material = new THREE.MeshPhongMaterial( {
		color:0xffffff,
		opacity:0,
		dithering:true,
		reflectivity:1,
		transparent: true,
		depthWrite:false,
		shadowSide:THREE.FrontSide
	} ); 
	
	const labelObjects = Object.keys(labels).map((key) => {
		
		// CREATE CSS3D OBJECT - MATCH WITH 3D LABEL

		const labelDiv = document.createElement( 'div' );
		labelDiv.className = 'labelContainer';
		
		const labelContent = document.createElement( 'div' );
		labelContent.className = 'label';
		labelContent.style = 'animation-delay:'+(key * .2)+'s';
		labelDiv.appendChild( labelContent )
		setTimeout(()=>{
			labelContent.style.opacity = '1';
			labelContent.style.animation = 'none';
		},1500)
		
		labelContent.appendChild( document.getElementById('label_'+key) )
	
/*
		const labelTitle = document.createElement( 'div' );
		labelTitle.className = 'labelTitle';
		labelTitle.textContent = labels[key].title;
		labelContent.appendChild( labelTitle )

		const labelText = document.createElement( 'div' );
		labelText.className = 'labelText';
		labelText.innerHTML = labels[key].text;
		labelContent.appendChild( labelText )
*/
		
		const labelCSS = new CSS3DObject( labelDiv );
		labelCSS.position.x = labels[key].initPosition.x;
		labelCSS.position.y = labels[key].initPosition.y;
		labelCSS.position.z = labels[key].initPosition.z;
		labelCSS.scale.set(.1,.1);
		
		css_scene.add( labelCSS );
		
		// BUTTON
		
		
		const labelButtonContainer = document.createElement( 'div' );
		labelButtonContainer.className = 'labelButtonContainer';

		const labelButtonDiv = document.createElement( 'div' );
		labelButtonDiv.className = 'labelButton';
		labelButtonDiv.textContent = labels[key].buttonText;
		labelButtonContainer.append(labelButtonDiv)
		
		const labelButtonCSS = new CSS3DObject( labelButtonContainer );
		labelButtonCSS.position.x = labels[key].initPosition.x;
		labelButtonCSS.position.y = labels[key].initPosition.y - 50;
		labelButtonCSS.position.z = labels[key].initPosition.z + 4;
		labelButtonCSS.scale.set(.1,.1);
		
		css_scene.add( labelButtonCSS );
		
		// ADD TO LABEL OBJECT
		
		labels[key].labelCSS = labelCSS;
		labels[key].labelDiv = labelContent;
		labels[key].labelButtonContainer = labelButtonContainer;
		labels[key].labelButton = labelButtonDiv;
		
		labelDiv.onclick = () => handleLabelClick( labels[key],'open' );
		labelDiv.onmouseenter= () => handleLabelHover( labels[key],'enter' );
		labelDiv.onmouseleave= () => handleLabelHover( labels[key],'leave' );
		
		return labels[key];
	});
	
	return labelObjects;
	
}
	
export default createLabels;