import * as THREE from "three";

export function createLights( scene ){

    const lights = [];
    lights[ 1 ] = new THREE.PointLight( 0xffffff, 134.6 );
    lights[ 1 ].position.set( 50, 100, 100 );

    scene.add( lights[ 1 ] );
    
    
    
    const hemiLight = new THREE.HemisphereLight( 0xfcf8ed, 0x95855c, 3 );
	hemiLight.position.set( 0, 1, 0 );
	hemiLight.name = "hemiLight";
	scene.add( hemiLight );


    
	const aoLight = new THREE.AmbientLight( 0xFFFFFF , .4); // soft white light
	aoLight.name = "aoLight";
//  	scene.add( aoLight );


    
    const shadowLight = new THREE.DirectionalLight( 0xffffff, .5);
	shadowLight.position.set( 0, 100, 20 );
	shadowLight.target.position.set( -5, 90, -10 );
	shadowLight.penumbra = 0;
	shadowLight.decay = 0;
	shadowLight.distance = 100;
	shadowLight.name = "shadowLight";

	shadowLight.castShadow = true;
	shadowLight.shadow.mapSize.width = 4048;
	shadowLight.shadow.mapSize.height = 4048;
	shadowLight.shadow.camera.left = -500;
	shadowLight.shadow.camera.right = 500;
	shadowLight.shadow.camera.top = 500;
	shadowLight.shadow.camera.bottom = -500;
	shadowLight.shadow.camera.near = 1;
	shadowLight.shadow.camera.far = 100;

	shadowLight.name = "shadowLight";

// 	scene.add( shadowLight );
// 	scene.add( shadowLight.target );

};
	
export default createLights;