import * as THREE from "three";
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

export function createCSSScene( mount ){
	
    const width = mount.clientWidth;
    const height = mount.clientHeight;

    const scene = new THREE.Scene();
    
    const renderer = new CSS3DRenderer();
    renderer.setSize( width, height );
        
    // ADD SCENE TO MOUNT
    mount.appendChild( renderer.domElement );
    
    document.getElementsByClassName("viewport")[0].getElementsByTagName("div")[0].classList.add('cssRenderer');
    
    return [ scene, renderer ];
    
};
	
export default createCSSScene;